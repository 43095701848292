


































.indexpage-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    padding: 16px;
    box-sizing: border-box;
    position: relative;
}
.indexpage-settings-button {
    position: absolute;
    top: 18px;
    right: 8px;
    opacity: .8;
    cursor: pointer;
    stroke: #797979;
    width: 18px;
    height: 18px;
    &:hover {
        opacity: 1;
    }
}
.indexpage-logo {
    display: block;
    width: 180px;
    height: 180px;
    margin: 0 auto 24px;
}

@media screen and (max-width: 480px) {
    .indexpage-container {
        padding: 16px 8px;
    }
}

.indexpage-search {
    max-width: 600px;
    font-size: 16px;
    .search-input {
        border-radius: 12px;
        border: 2px solid var(--card-border-color);
        box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
        background: var(--indexpage-search-background-color);
        &__input {
            padding: 12px 24px;
            background: transparent;
        }
    }
    .search-results {
        padding-bottom: 8px;
        &__link {
            margin: 4px 4px -4px 6px;
            border-radius: 8px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .search-result {
        &__left {
            margin-left: -4px;
        }
    }
}

@media screen and (max-width: 480px) {
    .indexpage-search {
        .search-input__input {
            padding-left: 18px;
            padding-right: 18px;
        }
        .search-results {
            &__link {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
