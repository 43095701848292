
.chart-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 16px;
    flex: 1;
    header {
        opacity: .5;
        margin-bottom: 8px;
    }
    &__value {
        font-size: 28px;
    }
    footer {
        opacity: .3;
    }
}
