
















































































































.jetton-list__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    &--rastopyrka {
        visibility: hidden;
        height: 0;
    }
}
