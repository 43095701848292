






























































































































































































































































































.page-nominator-top {
    display: flex;
    flex-direction: row;
}
.page-nominator-qr-card {
    padding: 14px;
    margin-right: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    justify-content: space-between;
    &__qr-code {
        border-radius: 4px;
        margin-bottom: 14px;
    }
    &__text {
        margin: 10px auto -4px;
        font-size: 13px;
        text-align: center;
        width: 100%;
        overflow: hidden;
        max-width: 235px;
        display: block;
        b {
            font-weight: 500;
        }
    }
}
// Override .card + .card
.page-nominator-info-card {
    margin-top: 0 !important;
    flex-grow: 1;
}

.page-nominator-validator-activity {
    width: 16px;
    height: 16px;
    margin-right: 2px;
    margin-bottom: -1px;
    &--active {
        fill: var(--green-bright);
    }
    &--pending {
        fill: var(--yellow-bright);
    }
    &--inactive {
        fill: var(--badge-grey-color);
    }
}

@media screen and (max-width: 740px) {
    .page-nominator-top {
        flex-direction: column-reverse;
    }
    .page-nominator-qr-card {
        margin: 18px 0 0 0;
        &__qr-code {
            display: none;
        }
        &__text {
            max-width: unset;
        }
    }
}
