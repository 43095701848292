
.line-chart-ear {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 16px 0 0;
    width: 155px;
    box-sizing: border-box;
}
.line-chart-ear-box {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    padding: 0 16px;
    header {
        opacity: .5;
        margin-bottom: 6px;
        font-size: 14px;
    }
    &__value {
        font-size: 22px;
        margin-bottom: 5px;
    }
    footer {
        font-size: 13px;
    }
}

.card-title-selector {
    display: flex;
    align-items: center;
    padding: 3px;
    margin: -3px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.4);
    text-transform: none;
    margin-left: auto;
    &__item {
        padding: 4px 12px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: normal;
        cursor: pointer;
        text-transform: lowercase;
        white-space: nowrap;
        &--active {
            background: #212121;
        }
    }
}
