
















































































































































































































.vote-casting-button {
    &__icon {
        width: 24px;
        height: 24px;
        fill: currentColor;
    }
}
.vote-casting-container {
    margin: 0 16px;
    &__buttons {
        margin: 16px 0 0 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 14px;
        .bright-blue-button {
            font-size: 24px;
        }
    }
    &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin: 0;
        padding: 14px 0;
        svg {
            fill: currentColor;
            flex-shrink: 0;
            margin-right: 5px;
            width: 14px;
            height: 14px;
        }
    }
}
@media all and (max-width: 480px) {
    .vote-casting-container {
        margin: 0 10px;
        &__buttons {
            .bright-blue-button {
                font-size: 18px;
            }
        }
        &__text {
            padding: 10px 0 14px;
            font-size: 13px;
            svg {
                display: none;
            }
        }
    }
}

.vote-results-bar-container {
    padding: 16px 18px 8px;
}
.vote-results-bar {
    display: flex;
    align-items: center;
    background: var(--stacked-bar-background);
    height: 12px;
    width: 100%;
    border-radius: 36px;
    overflow: hidden;
    &__stripe {
        width: 24%;
        height: 100%;
        border-right: 1px solid var(--stacked-bar-background);
        box-sizing: border-box;
        min-width: 2px;
        transition: .4s width ease;
        &:first-child {
            min-width: 3px;
        }
        &--green {
            background: var(--stacked-bar-green);
        }
        &--red {
            background: var(--stacked-bar-red);
        }
    }
}
.vote-results-legend {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: 8px;
    flex-wrap: wrap;
    &__result {
        display: flex;
        align-items: center;
        margin-right: 24px;
        line-height: 1.8;
        &:last-child {
            margin-right: 0;
        }
    }
    &__ricegrain {
        width: 10px;
        height: 4px;
        margin-right: 6px;
        display: inline-block;
        border-radius: 18px;
        &--red {
            background: var(--stacked-bar-red);
        }
        &--green {
            background: var(--stacked-bar-green);
        }
        &--meh {
            background: var(--stacked-bar-meh);
        }
    }
}
