



































































































.storage-provider-param-selector {
    color: var(--blue-bright);
    cursor: pointer;
    border-bottom: 1px dotted;
}
