

































.ui-inline-nft {
    display: flex;
    align-items: center;
    background: var(--body-light-muted-color);
    border-radius: 4px;
    margin: -4px 0;
    color: inherit;
    &__image {
        width: 26px;
        height: 26px;
        border-radius: 4px 0 0 4px;
        object-fit: cover;
        background: var(--card-border-color);
    }
    &__title {
        padding: 5px 10px;
        font-size: 13px;
    }
}
