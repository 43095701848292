











































































.card-title-tabs {
    display: flex;
    align-items: center;
    overflow-x: auto;
    scrollbar-width: none;
    flex-wrap: nowrap;
    flex-direction: row;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: 1px solid var(--card-row-separator);
    background: var(--card-header-background);
    color: var(--card-header-color);
    padding: 0 12px;
    &::-webkit-scrollbar {
        display: none;
    }
}
.card-title-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 32px;
    cursor: pointer;
    transition: .2s all ease;
    white-space: nowrap;
    color: var(--card-header-tab-color);
    &__icon {
        fill: currentColor;
        margin-right: 10px;
        width: 24px;
        height: 24px;
    }
    &:hover, &:focus, &:focus-visible {
        color: var(--body-text-color);
        box-shadow: 0 -2px 0 0 var(--body-muted-text-color) inset;
        outline: none;
    }
    &--active {
        &, &:hover, &:focus {
            color: var(--body-text-color);
            box-shadow: 0 -2px 0 0 var(--blue-bright) inset;
        }
        .card-title-tab__icon {
            fill: var(--blue-bright);
        }
    }
    &--disabled {
        pointer-events: none;
        color: var(--body-muted-text-color);
        opacity: .45;
    }
}

@media all and (max-width: 480px) {
    .card-title-tab {
        flex-grow: 1;
        flex-basis: 150px;
        padding: 13px 26px;
        font-size: 13px;
        &__icon {
            display: none;
        }
    }
}
